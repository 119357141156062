import React from "react"
import Seo from "../../components/Seo/Seo"
import "../contentpage.sass"
import PageTransition from "../../components/PageTransition"
import ReactGA from "react-ga"
import PageHeader from "../../components/PageHeader"

const EventsPage = (props) => {

	return (

		<PageTransition {...props}>

			<Seo 
				title="Get Deviant"
				description='Find out how to support the Deviant Robot project.'
				pathname="/getdeviant"/>

			<PageHeader title="Get Deviant" />

			<div className="page contentPage">

				<section>

					<h2>More coming soon</h2>

					<p>We are working on lots of ways for you to get more involved with help, support, follow and generally be all about Deviant Robot. But for now, the options are a little limited. Regardless do not let that stop you going nuts with the following links: -</p>

				</section>

			</div>

		</PageTransition>

	)

}

export default EventsPage
